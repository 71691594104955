.discount-codes-table-wrapper{
    .MuiDataGrid-main{
        .MuiDataGrid-columnHeaders {
            .table-header-custom-styles{
                height:108px;
                & > div{
                    height: 32px;
                    & input {
                        margin-top: 5px;
                        background-color: rgba(200,200,200,0.1);
                        border-radius: 6px ;

                    }
                }
            }
        }
    }
}
