@import "src/style/mixins.scss";

.edit-form-textField{
    width: 300px;
    margin-left: 5px;
    border: 1px solid rgba(58, 53, 65, 0.21);
    border-radius: 3px;
    &:focus{
        outline: 0;
        border-color: #1c7430;
    }
}
.form-question-wrapper{
    margin-bottom: 15px;
    padding:15px;
    border-radius: 6px;
    width: 1040px;
    .form-button-remove{
        color:#fff
    }
    table{
        .MuiTableCell-root{
            padding: 6px ;
        }
    }
}
.editor-custom-styles{
    background-color: rgba(159, 159, 159, 0.1);
    margin-bottom: 30px;
    height: auto;
}
.form-answers-table-wrapper{
    width: 100%;
    white-space: nowrap;
    overflow: auto;
    overflow-y: hidden;
    margin: 0 auto;
}
.form-control-datePicker{
    width: 100%;
    margin: (1rem inherit 1rem inherit);

    @include sm{
        margin-left: 2rem;
        margin-right: 2rem;
    }
    @include md{
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}
.form-label-textField{
    line-height: 42px;
    margin-right: 10px;
    @include sm{
        width: 175px;
    }
    @include md{
        width: unset;
        margin-right: 1.5rem;
    }
}
.form-label{
    width: 100%;
    margin-left: 1rem;
    margin-right: 1rem;
    align-items: flex-start;
    @include md{
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        align-items: center;
    }
    @include sm{
        align-items: flex-start;
        margin-left: 0;
        margin-right: 0;
    }
}
