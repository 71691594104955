@mixin xs {
    @media (max-width: 599px) { @content; }
}
@mixin xs-height {
    @media (max-height: 699px) { @content; }
}
@mixin xxs-height {
    @media (max-height: 529px) { @content; }
}
@mixin sm {
    @media (max-width: 600px) { @content; }
}
@mixin md {
    @media (max-width: 900px) { @content; }
}
@mixin lg {
    @media (max-width: 1200px) { @content; }
}
@mixin xlg {
    @media (max-width: 1800px) { @content; }
}
@mixin modal-form-styles{
    position: relative;
    background-color: #fefefe;
    padding: 20px;
    margin:50px auto;
    width:  90%;
    box-shadow: 0 2px 10px 0 rgba(76, 78, 100, 0.22);
    border-radius: 1rem;
    @content;
}
