@import "src/style/mixins.scss";

.edit-polls-textField{
    width: 400px;
    margin-left: 25px;
    border: 1px solid rgba(58, 53, 65, 0.21);
    border-radius: 6px;
    &:focus{
        outline: 0;
        border-color: #1c7430;
    }
    @include sm{
        width: 80%;
    }
}
