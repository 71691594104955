@import "mixins.scss";
@import "src/components/PageEditPermissions/EditPermissionsTab/EditPermissions";
@import "src/components/PageMembershipVerification/MembershipVerification.scss";
@import "src/components/PageUsers/PageUsers.scss";
@import "src/components/PageEditForms/PageEditForms";
@import "src/components/PageEditUser/_PageEditUser.scss";
@import "../components/PageEditPolls/EditPolls";
@import "src/components/PageVerificationStatement/VerificationStatement.scss";
@import "src/components/PageContactTypes/ContactTypes";
@import "src/components/PageDiscountCodes/DiscountCodes";

html{
    font-size: var(--global-font-size) !important;
}
p{
    margin-bottom: unset;
}
.button-custom-styles{
    color: #000
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
.MuiMenuItem-gutters.Mui-selected {
    background-color: rgba(86, 202, 0, 0.5) !important; ;
}
.MuiTreeItem-content.Mui-selected{
    background-color: transparent !important;
    &:hover{
        background-color: rgba(58, 53, 65, 0.04) !important;
}
}
.css-11f4fwi-MuiTreeItem-content.MuiTreeItem-content.Mui-selected{
    background-color: unset ;
}
.form-control{
    padding:0;
    border: none;
}
.array-modal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include xs-height {
        top: 340px;
    } @include xxs-height {
        top: 240px;
    }
    .result-of-verification-wrapper{
        .MuiBox-root{
        @include xs-height {
            height: 60vh;
          }
      }
    }
}
.array-close-button{
    position: absolute;
    right: 15px;
    top: 10px;
}
.noScroll {
    overflow: hidden;
}
.modal-window-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.7);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    transition: all 0.3s;
    .modal-content {
        @include modal-form-styles{
            max-width: 1150px;
        }
        @include md{
            width: 100%;
        }
        @include sm{
            height: 100vh;
        }
    }
    .modal-content-form {
        @include modal-form-styles{
            overflow-x: auto;
            white-space: nowrap;
            max-width: 1400px;
        }
        @include md{
            width: 100%;
        }
        @include sm{
            height: 100vh;
        }
    }
    &:target {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
    }
    .iframe-poll{
        width: 900px;
        min-height: 900px;
        margin: 0 auto;
        @include lg{
            max-width: 900px;
            width: 100%;
        }
    }
}
.iframe-form{
    width: 1300px;
    min-height: 900px;
    margin: 0 auto;
    @include lg{
        max-width: 900px;
        width: 100%;
    }
}
.modal-close {
    color: #aaa;
    line-height: 50px;
    position: absolute;
    right: 20px;
    text-align: center;
    top: 0;
    text-decoration: none;
    &:hover {
        color: black;
    }
}
a {
    color: inherit;
    text-decoration: none;
}
.btn {
    text-decoration: none;
    border: none;
}
.nav-btn {
    background-color: transparent;
    display: block;
    text-decoration: none;
    border: none;
    text-transform: capitalize;
    color: rgba(58, 53, 65, 0.87);;
    padding: 9px 14px 9px 22px;
    font-size: var(--global-font-size);
    width: 100%;
    text-align: left;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    .nav-btn-link {
        text-decoration: none;
        border: none;

        &:hover {
            color: inherit;
        }
    }
    &:hover {
        background-color: rgba(58, 53, 65, 0.04);
    }
}
.table-header-custom-styles {
    height: 92px;
    & > div {
        height: 32px;
        & input {
            margin-top: 5px;
            background-color: rgba(200, 200, 200, 0.1);
            border-radius: 6px;
        }
    }
}
[data-tip] {
    position:relative;
}
[data-tip]:before {
    content:'';
    display:none;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #1a1a1a;
    position:absolute;
    top:30px;
    left:35px;
    z-index:8;
    font-size:0;
    line-height:0;
    width:0;
    height:0;
}
[data-tip]:after {
    display:none;
    content:attr(data-tip);
    position:absolute;
    top:35px;
    left:0;
    padding:0 8px;
    background:#1a1a1a;
    color:#fff;
    z-index:9;
    font-size: 0.75em;
    height:18px;
    line-height:18px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    white-space:nowrap;
    word-wrap:normal;
}
[data-tip]:hover:before,
[data-tip]:hover:after {
    display:block;
}
.table-header-custom-styles-select{
    width: 350px;
    height:87px;
    & > div{
        height: 26px;
    }
}
.cellCheckbox{
    justify-content: center !important;
}

