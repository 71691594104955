.edit-permissions-tree-view {
    .edit-permissions-site-level {
        overflow-x: auto;
        overflow-y: hidden;
        .MuiInputLabel-shrink {
            top: 0;
        }

        .edit-permissions-wrapper {
            display: inline-block;
            margin-left: -210px;

            .Mui-selected, .Mui-focused, .Mui-expanded {
                background-color: unset;

            }

            & > .MuiTreeItem-content {
                height: 40px;
                margin-left: 220px;
                @media (min-width: 600px) {
                    width: calc(100% - 220px);
                }
            }

            .MuiInputLabel-shrink {
                top: 0;
            }
        }

        .edit-permissions-menu-level {
            & ul {
                margin-left: -210px;

                li {
                    margin-left: 120px;
                    width: calc(100% - 330px);
                    @media (max-width: 500px) {
                        margin-left: 40px;
                    }
                }
            }
        }


        .edit-permissions-modules-level {
            & ul {
                margin-top: 12px;
            }

            .edit-permissions-pagination {
                ul {
                    justify-content: center;

                    li {
                        margin-left: 12px;
                        width: unset;
                    }
                }
            }
        }

        .edit-permissions-paragraphs-level {
            margin-left: -122px;
            @media (max-width: 500px) {
                margin-left: -40px;

            }

            .edit-permissions-paragraphs-level-description {
                margin-left: 220px;
                @media (max-width: 500px) {
                    margin-left: 80px;
                }
            }

        }

    }
}
