.icon-button-custom {
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 14px;
    color:var(--font-color-theme) ;

}
.table-cell-custom {
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}
.collapse-table-cell-custom {
    padding-left: 1.25rem !important;

}
.table-row-summary {
    background-color: rgba(110, 110, 110, 0.3);
}
.table-row-summary-failed {
    background-color: var(--background-color-theme);
}
